<template>
    <v-navigation-drawer :right='!$vuetify.rtl' :value='open' :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'" app
        temporary touchless @input="(val) => $emit('update:open', val)">
        <v-card height='100%'>
            <div class='drawer-header d-flex align-center mb-4'><span
                    class='font-weight-semibold text-base text--primary'>添加新用户</span>
                <v-spacer></v-spacer>
                <v-btn icon small @click="$emit('update:open', false)">
                    <v-icon size='22'>{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </div>
            <v-card-text>
                <v-form ref='form' v-model='valid' @submit.prevent='onSubmit'>
                    <v-text-field v-model='content.username' :rules='[validators.required]' class='mb-6' dense
                        hide-details='auto' label='用户名' outlined placeholder='用户名'></v-text-field>
                    <v-text-field v-model='content.nickname' :rules='[validators.required]' class='mb-6' dense
                        hide-details='auto' label='昵称' outlined placeholder='昵称'></v-text-field>
                    <v-text-field v-model='content.password' :rules='[validators.required]' class='mb-6' dense
                        hide-details='auto' password label='密码' type="password" outlined placeholder='密码'></v-text-field>
                    <v-textarea v-model='content.desc' :rules='[]' class='mb-6' dense hide-details='auto' label='描述'
                        outlined placeholder='描述' type='number'></v-textarea>
                    <v-btn class='me-3' color='primary' type='submit'>添加</v-btn>
                    <v-btn color='secondary' outlined type='reset' @click='resetUserData'>取消</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>
<script>
import { mdiClose } from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
    model: { prop: 'open', event: 'update:open', },
    props: { open: { type: Boolean, required: true, }, },
    setup(props, { emit }) {
        const contentDefault = { username: '', nickname: '', password: '', desc: '' }
        const valid = ref(false)
        const form = ref(null)
        const validate = () => {
            form.value.validate()
        }
        const resetForm = () => {
            form.value.reset()
        }
        const content = ref(JSON.parse(JSON.stringify(contentDefault)))
        const resetUserData = () => {
            content.value = JSON.parse(JSON.stringify(contentDefault))
            resetForm()
            emit('open', false)
        }
        const onSubmit = () => {
            if (valid.value) {
                store.dispatch('app-system/addUser', content.value).then(() => {
                    emit('update:open', false)
                    emit('refetch-data')
                })
                resetUserData()
            } else {
                validate()
            }
        }
        return { resetUserData, form, onSubmit, content, valid, validate, validators: { required }, icons: { mdiClose, }, }
    },
} </script>
