import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUserList() {
    const headers = [
        { text: '用户名', value: 'username' },
        { text: '昵称', value: 'nickname' },
        { text: '描述', value: 'desc' },
        { text: '状态', value: 'state' },
        { text: '创建时间', value: 'create_at', sortable: false },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false
    })
    const enums = {
        status: { "1": "启用", "2": "禁用" },
        statusColor: { "1": "success", "2": "error" },
    }

    const fetchUserList = () => {
        table.value.loading = true
        store.dispatch('app-system/fetchUserList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                loading.value = false
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
            table.value.loading = false
        }).catch(error => {
            console.log(error)
            table.value.loading = false
        })
    }

    const fetchUserRoleList = (userID) => {
        table.value.loading = true
        store.dispatch('app-system/fetchUserRoleList', userID).then(res => {
            console.log(res);
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
        }).catch(error => {
            console.log(error)
            table.value = { open: true, msg: `网络错误：${error}` }
        }).finally(table.value.loading = false)
    }

    const delUser = (id) => {
        table.value.loading = true
        store.dispatch('app-system/delUser', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                loading.value = false
                return
            }
            table.value.loading = false
            fetchUserList()
        }).catch(error => {
            table.value.loading = false
        })
    }
    const enableUser = (id) => {
        table.value.loading = true
        store.dispatch('app-system/enableUser', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                loading.value = false
                return
            }
            table.value.loading = false
            fetchUserList()
        }).catch(error => {
            table.value.loading = false
        })
    }
    const disableUser = (id) => {
        table.value.loading = true
        store.dispatch('app-system/disableUser', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                loading.value = false
                return
            }
            table.value.loading = false
            fetchUserList()
        }).catch(error => {
            table.value.loading = false
        })
    }

    watch([param], () => {
        fetchUserList()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        fetchUserList, delUser, fetchUserRoleList, enableUser, disableUser
    }
}
