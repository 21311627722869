<template>
    <div id='user-list'>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <user-add v-model='drawer.add' @refetch-data='fetchUserList'></user-add>
        <user-update v-model='drawer.update' @refetch-data='fetchUserList' :data="drawer.updateData"></user-update>
        <user-update-pwd v-model='drawer.updatePass' @refetch-data='fetchUserList'
            :data="drawer.updatePassData"></user-update-pwd>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined
                    placeholder='搜索'></v-text-field>
                <v-spacer></v-spacer>
                <div class='d-flex align-center flex-wrap'>
                    <v-btn class='mb-4 me-3' color='primary' @click.stop='drawer.add = !drawer.add'>
                        <v-icon>{{ icons.mdiPlus }}</v-icon>
                        <span>添加用户</span>
                    </v-btn>
                </div>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state] }}</v-chip>
                </template>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-menu bottom left>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn icon v-bind='attrs' v-on='on'>
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openEdit(item)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20'>{{ icons.mdiPencil }}</v-icon>
                                    <span>编辑</span></v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openReset(item)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20' color="warning">{{ icons.mdiLock }}</v-icon>
                                    <span>重置密码</span></v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item @click="openRole(item)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20'>{{ icons.mdiPencil }}</v-icon>
                                    <span>角色</span></v-list-item-title>
                            </v-list-item> -->
                            <v-list-item v-if="item.state == 1" @click="disableUser(item.id)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20' color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                                    <span>禁用</span></v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else @click="enableUser(item.id)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20' color="success">{{ icons.mdiDeleteOutline }}</v-icon>
                                    <span>启用</span></v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.state != 1" @click="delUser(item.id)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20' color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                                    <span>删除</span></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, mdiLock } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import systemStoreModule from '../systemStoreModule'
import UserAdd from './UserAdd.vue'
import UserUpdate from './UserUpdate.vue'
import UserUpdatePwd from './UserUpdatePwd.vue'
import useUserList from './useUsersList'

export default {
    components: { UserAdd, UserUpdate, UserUpdatePwd }, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-system'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, systemStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, fetchUserList, fetchUserRoleList, delUser, enableUser, disableUser } = useUserList()
        const drawer = ref({
            add: false,
            update: false,
            updateData: {},
            updatePass: false,
            updatePassData: {},
        })

        const openEdit = (data) => {
            drawer.value.update = true
            drawer.value.updateData = data
        }
        const openReset = (data) => {
            drawer.value.updatePass = true
            drawer.value.updatePassData = data
        }

        const openRole = (data) => {
            fetchUserRoleList()
        }

        fetchUserList()

        return {
            headers,
            param,
            table, enums,
            fetchUserList, delUser, openEdit, openRole, openReset, enableUser, disableUser,
            drawer,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, mdiLock },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
