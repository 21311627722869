import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {
        fetchUserList(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/user/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchUser(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/user/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addUser(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.post('/admin-company/v1/system/user', content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateUser(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/user/${content.id}`, content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateUserPass(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/user/password/${content.id}`, content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        enableUser(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/user/enable/${content}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        disableUser(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/user/disable/${content}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delUser(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/system/user/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchUserRoleList(ctx, userID) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/rbac/user-role/list`, { params: { user_id: userID } }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchRoleList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/rbac/role/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addRole(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.post('/admin-company/v1/system/rbac/role', content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateRole(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/rbac/role/${content.id}`, content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delRole(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/system/rbac/role/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchPrivilegeList(ctx, queryParams) {
            console.log(queryParams)
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/rbac/privilege/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchSettingList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/system/setting/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addSetting(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.post('/admin-company/v1/system/setting', content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        updateSetting(ctx, content) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/system/setting/${content.id}`, content).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delSetting(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/system/setting/${data.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
